<template>
  <div
    class="d-button"
    :class="buttonClassOptions"
  >
    <!-- :color="color"
    :size="size" -->
    <!-- :label="label" -->
    <AIconify
      v-if="iconName"
      :name="iconName"
    />
    <span
      v-if="text"
      class="d-button__text"
    >
      {{text}}
    </span>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

import AIconify from '@/shared/components/a-iconify'

import { D_BUTTON_TYPE, D_BUTTON_SIZE } from './d-button-constants';

const props = defineProps({
  type: {
    type: String,
    default: 'default',
  },
  text: {
    type: String,
    default: '',
  },
  iconName: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: D_BUTTON_SIZE.MD,
  },
})

const buttonClassOptions = computed(() => {
  return {
    'd-button--outlined': props.type === D_BUTTON_TYPE.OUTLINED,
    'd-button--tertiary': props.type === D_BUTTON_TYPE.TERTIARY,
    'd-button-size--md': props.size === D_BUTTON_SIZE.MD,
    'd-button-size--sm': props.size === D_BUTTON_SIZE.SM,
    'd-button-size--xsm': props.size === D_BUTTON_SIZE.XSM,
  }
})

</script>

<style lang="scss">
@import './d-button.scss';
</style>