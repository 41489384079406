<template>
  <div class="o-header">
    <div class="header-container">
      <div class="o-header__left">
        <ATooltip
          v-if="!isSidebarPinned"
          content="Recent chats"
        >
          <AIconifyButton
            name="recently-viewed"
            @click="onClickShowSidebar"
          />
        </ATooltip>
        <ATooltip
          content="New chat"
        >
          <AIconifyButton
            name="add-large"
            @click="onClickNewChat"
          />
        </ATooltip>
      </div>
      <div class="o-header__right">
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, defineProps } from 'vue';
import { eventTypes } from '../../shared/constants/bus-events-constants';

import AButtonRecent from '@/components/a-button-recent'
import ATooltip from '@/shared/components/a-tooltip'
import AIconifyButton from '@/shared/components/a-iconify-button'

import {
  CLIENT_NAMES,
  SHOW_DATA_TAB,
} from '../../constants'

import { useBus } from '@/shared/composables'

const props = defineProps({
  isSidebarPinned: {
    type: Boolean,
    default: () => false,
  },
})

const { busEmit } = useBus()

const TABS = {
  CHAT: { title: 'Chat', pathName: 'chat' },
  DATA: { title: 'Data', pathName: 'data' },
}

const clientDatricsTabs = [
    TABS.CHAT,
]
const clientCfoTabs = [
  TABS.CHAT,
]
const clientCfoTbiTabs = [
  TABS.CHAT,
]
if (SHOW_DATA_TAB) {
  clientCfoTbiTabs.push(TABS.DATA)
  clientCfoTabs.push(TABS.DATA)
}
const clientSigmaTabs = [
  TABS.CHAT,
  TABS.DATA
]

const clientTabs = {
  [CLIENT_NAMES.DATRICS]: clientDatricsTabs,
  [CLIENT_NAMES.CFO]: clientCfoTabs,
  [CLIENT_NAMES.CFO_TBI]: clientCfoTbiTabs,
  [CLIENT_NAMES.SIGMA]: clientSigmaTabs,
}

const store = useStore()

const useCache = ref(store.state.config.useCache)

const onUseCacheChange = (value) => {
  console.log('onUseCacheChange', value)
  store.dispatch('config/setUseCache', value)
}
function onClickShowSidebar() {
  busEmit(eventTypes.SIDEBAR.SHOW)
}
function onClickNewChat() {
  busEmit(eventTypes.SIDEBAR.NEW_CHAT)
}
</script>

<style lang="scss">
@import "o-header.scss";
</style>