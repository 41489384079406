export const D_BUTTON_TYPE = {
  DEFAULT: 'default',
  OUTLINED: 'outlined',
  TERTIARY: 'tertiary',
}
export const D_BUTTON_SIZE = {
  XSM: 'xsm',
  SM: 'sm',
  MD: 'md',
}
export const THEME_OPTIONS = [D_BUTTON_TYPE.DEFAULT, D_BUTTON_TYPE.OUTLINED]