<template>
  <div
    class="a-iconify-button"
    :class="classOptions"
    @click="onClick"
  >
    <AIconify
      :name="name"
      :label="label"
      :color="color"
      :size="size"
    />
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  defineProps,
  defineEmits,
} from 'vue'

import { DUI_COLORS } from '../../constants/dui-colors';
import AIconify from '@/shared/components/a-iconify'
import { DUI_KEY } from '../../constants/dui-colors';

const props = defineProps({
  label: {
    type: String,
    default: 'carbon',
  },
  /**
   * Icon name from https://icon-sets.iconify.design/carbon/?query=side
   */
  name: {
    type: String,
    validator: val => !val.match(/[.]/g),
    default: '',
  },
  /**
   * Element colors
   */
  color: {
    type: String,
    default: DUI_KEY['DUI-GREY-500'],
  },
  /**
   * Element font size
   *
   * by default it will set size points in 'px'
   * example: 20 or '20'
   */
  size: {
    type: String,
    default: '16',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])
const classOptions = computed(() => ({
  'a-iconify-button--disabled': props.isDisabled,
}))
const onClick = () => {
  if (props.isDisabled) return
  emit('click')
}
</script>

<style lang="scss">
@import './a-iconify-button.scss';
</style>
