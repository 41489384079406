<template>
  <div
    class="o-sidebar"
    :class="sidebarClassOptions"
  >
    <div class="sidebar__header">
      <div class="sidebar__header-title">
        <p>
          Recent chats
        </p>
        <div
          class="sidebar__header-pin"
          @mouseenter="isPinIconHover = true"
          @mouseleave="isPinIconHover = false"
        >
          <ATooltip
            v-if="!isSidebarPinned && !isPinIconHover"
            content="Pin sidebar"
          >
            <AIconifyButton
              name="pin"
              @click="onClickPinSidebar"
            />
          </ATooltip>
          <ATooltip
            v-else-if="!isSidebarPinned&&isPinIconHover"
            content="Pin sidebar"
          >
            <AIconifyButton
              name="pin-filled"
              @click="onClickPinSidebar"
            />
          </ATooltip>
          <ATooltip
            v-else-if="isSidebarPinned && isPinIconHover"
            content="Unpin sidebar"
          >
            <AIconifyButton
              name="pin"
              @click="onClickUnpinSidebar"
            />
          </ATooltip>
          <ATooltip
            v-else
            content="Unpin sidebar"
          >
            <AIconifyButton
              name="pin-filled"
              @click="onClickUnpinSidebar"
            />
          </ATooltip>
        </div>
      </div>
      <DButton
        text="New chat"
        type="outlined"
        icon-name="add-large"
        size="sm"
        @click="onClickNewChat"
      />
    </div>
    <div class="sidebar__chats">
      <AConversationItem
        v-for="item in conversations"
        :key="item.uuid"
        :item="item"
        @onClickDeleteConversation="onClickDeleteConversation"
        @click="onClickConversation(item)"
      >
        {{ item.title }}
      </AConversationItem>
    </div>
  </div>
</template>

<script setup>
import * as uuid from 'uuid'
import { ref, defineProps, onMounted, onUnmounted } from 'vue'

import AConversationItem from './a-conversation-item'
import DButton from '@/shared/components/d-button'
import AIconifyButton from '@/shared/components/a-iconify-button'
import ATooltip from '@/shared/components/a-tooltip'

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, computed } from 'vue'
import router from '../../router'
import { useBus } from '@/shared/composables'

import { eventTypes } from '@/shared/constants/bus-events-constants';
import { useConversation } from '../../composables/useConversation'
const props = defineProps({
  isSidebarPinned: {
    type: Boolean,
    default: () => false,
  },
})

const route = useRoute()
const store = useStore()
const { busOn, busOff } = useBus()
const {
  archiveConversation,
} = useConversation()

const isPinIconHover = ref(false)

const conversations = computed(() => store.state.conversations.conversations)
const sidebarClassOptions = computed(() => {
  return {
    'o-sidebar--unfixed': !props.isSidebarPinned,
  }
})
const emit = defineEmits(['onChangeConversation'])
const onClickConversation = (item) => {
  router.push({ name: 'chat', query: { ...route.query, conversationUuid: item.uuid } })
  emit('onChangeConversation', item)
}
function onClickPinSidebar() {
  emit('onClickPinSidebar')
}
function onClickUnpinSidebar() {
  emit('onClickUnpinSidebar')
}
const onClickNewChat = () => {
  onClickConversation({ uuid: uuid.v4() })
}

function initialization() {
  busOn(eventTypes.SIDEBAR.NEW_CHAT, onClickNewChat)
}

function deInitialization() {
  busOff(eventTypes.SIDEBAR.NEW_CHAT, onClickNewChat)
}

async function onClickDeleteConversation(conversation) {
  const payload = {
    uuid: conversation.uuid,
    user_uuid: conversation.user_uuid,
    descriptor_base_path: conversation.descriptor_base_path,
  }
  await archiveConversation(payload)
  if (conversations.value.length === 0) {
    onClickNewChat()
  } else if (conversations.value.length > 0) {
    onClickConversation(conversations.value[0])
  }
}

onMounted(() => {
  initialization()
})
onUnmounted(() => {
  deInitialization()
})
</script>

<style lang="scss">
@import "o-sidebar.scss";
</style>
