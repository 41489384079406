<template>
  <div
    class="o-body"
    :class="bodyClassOptions"
  >
    <div class="o-body-gradient-top"></div>
    <div class="o-body__wrapper">
      <OBodyMessages id="messages">
        <OMessage
          v-for="message in messages"
          :key="message.key"
          :message="message"
          :ai-analyst="aiAnalyst"
          @onSetMessage="onSetMessage"
          @onSelectFeedback="onSelectFeedback"
        />
      </OBodyMessages>
      <div class="o-body__input-container">
        <ATextArea
          v-model="prompt"
          :disabled="isLoading || isDisabled"
          :is-auto-rows="true"
          :max-rows="5"
          @onEnter="onClickSend"
        />
        <AButtonSend
          v-if="!isLoading"
          @click="onClickSend"
          :disabled="isLoading || !prompt || isDisabled"
        />
        <div
          v-if="isLoading"
          @mouseover="() => isHovered = true"
          @mouseleave="() => isHovered = false"
        >
          <v-progress-circular
            v-if="!isHovered"
            :size="50"
            :width="5"
            indeterminate
          />
          <AButtonCancel
            v-else
            @click="handleCancelTask"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as uuid from 'uuid';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  reactive, ref, watch, defineProps, computed, onMounted, onBeforeUnmount,
} from 'vue';

import ATextArea from '../a-textarea';
import OMessage from '../o-message'
import OBodyMessages from './o-body-messages'
import AButtonSend from '../a-button-send/a-button-send.vue';
import AButtonCancel from '../a-button-cancel/a-button-cancel.vue';

import { API_BASE_URL } from '@/api/backend-api-client';
import {
  getCharts,
  getTables,
  getConversation,
  createConversation,
  cancelTask,
  getProject,
  initProject,
  resetCacheProject,
} from '../../api/conversation.api'
import { ARGS } from '../../constants/ai-analyst-constants';
import { useScroll } from './use-scroll';
import { useConversation } from '../../composables/useConversation'
import { useIframeMessaging } from '../../composables/useIframeMessaging';
import { getAiAnalystItem } from '@/shared/api/ai-analyst.api'
import { useSanitize } from '../../composables/use-sanitize';

import {
  SUBST_BOT_NAME,
  suggestedQuestions,
  SHOW_SWITCH,
} from '../../constants'
import { eventTypes } from '@/shared/constants/bus-events-constants';

const props = defineProps({
  conversation: {
    type: Object,
    default: () => null,
  },
  isSidebarPinned: {
    type: Boolean,
    default: () => false,
  },
})


const store = useStore()
const route = useRoute()
const router = useRouter()
const {
  scrollDetect,
  scrollToBottom,
} = useScroll()
const { getConversations } = useConversation()
const {
 sanitize,
} = useSanitize()

const userUuid = ref(localStorage.getItem('userUuid'))
const prompt = ref('')
const windowWidth = ref(window.innerWidth)
const messages = ref([])
const isLoading = ref(false)
const isDisabled = ref(false)
const conversationUuid = ref(null)
const routeConversationUuid = computed(() => route.query.conversationUuid)
const routeDescriptorBasePath = computed(() => route.query.descriptorBasePath || route.query.basePath)
const apiKeyUuid = computed(() => route.query.apiKeyUuid)
const routeUserUuid = computed(() => route.query.userUuid)
const messageUuid = ref(null)
const generatedQuestions = ref([])
const taskId = ref(null)
const isHovered = ref(false)
const isSummaryShowTables = ref(false)
const aiAnalyst = ref(null)
const descriptorData = ref({})

const bodyClassOptions = computed(() => {
  const sidebarWidth = 240
  const chatWidth = 840
  return {
    'o-body--align-start': props.isSidebarPinned && ((windowWidth.value - sidebarWidth) < chatWidth)
  }
})

const getUseCache = () => SHOW_SWITCH === true && localStorage.getItem('useCache') === 'true'

const handleGetAiAnalystItem = async () => {
  try {
    const payload = {
      apiKeyUuid: apiKeyUuid.value,
    }
    const response = await getAiAnalystItem(payload)
    aiAnalyst.value = response.data.aiAnalyst
    console.log('aiAnalyst', response.data)
  } catch (error) {
    console.error(error)
  }
}

const initProjectDescriptor = async (data = {}) => {
  if (!data.init) return
  try {
    isDisabled.value = true
    delete data.init
    await initProject({
      payload: {
        ...data,
      },
      query: {
        apiKeyUuid: apiKeyUuid.value,
      },
    })
  } catch (error) {
    console.error(error)
  } finally {
    isDisabled.value = false
  }
}

const handleGetChatProject = async () => {
  try {
    const { data } = await getProject({
      query: {
        descriptor_base_path: routeDescriptorBasePath.value,
        apiKeyUuid: apiKeyUuid.value,
      },
    })
    descriptorData.value = data
    generatedQuestions.value = data?.questions || data?.settings?.questions || []
    isSummaryShowTables.value = data.settings?.is_summary_show_tables
    await initProjectDescriptor(data)
  } catch (error) {
    console.error(error)
  }
}

function prepareGreeting() {
  if (descriptorData.value.settings?.[ARGS.GREETING]) {
    let gritting = descriptorData.value.settings?.[ARGS.GREETING]
    const publicName = descriptorData.value.settings?.[ARGS.PUBLIC_NAME] || SUBST_BOT_NAME || 'Datrics AI Analyst'
    gritting = gritting.replaceAll('[analyst_name]', publicName)
    gritting = sanitize(gritting)
    return gritting
  }
  return `${SUBST_BOT_NAME} AI Analyst, how can I help you?`
}

const generateInitialMessage = () => {
  const message = {
    uuid: uuid.v4(),
    type: 'system',
    title: prepareGreeting(),
    isLoading: false,
    questions: generatedQuestions.value || suggestedQuestions,
    feedback: { liked: null, comments: [] },
    key: uuid.v4(),
    tables: [],
    charts: [],
  }
  return message
}

const getMessageCharts = async (message_uuid) => {
  const { data } = await getCharts({
    payload: {
      uuid: message_uuid,
      user_uuid: routeUserUuid.value || userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
    },
    query: {
      apiKeyUuid: apiKeyUuid.value,
    },
  })
  return data.charts
}

const getMessageTables = async (message_uuid) => {
  const { data } = await getTables({
    payload: {
      uuid: message_uuid,
      user_uuid: routeUserUuid.value || userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
      is_summary_show_tables: isSummaryShowTables.value,
    },
    query: {
      apiKeyUuid: apiKeyUuid.value,
    },
  })
  return data.tables
}

const setCurrentConversation = (conversation) => {
  conversationUuid.value = conversation.uuid
  router.push({ query: { ...route.query, conversationUuid: conversation.uuid, userUuid: routeUserUuid.value || userUuid.value } })
}

const handleGetConversation = async ({ conversation_uuid }) => {
  await handleGetChatProject()
  messages.value = [generateInitialMessage()]
  if (!conversation_uuid) return
  setCurrentConversation({ uuid: conversation_uuid })

  const { data } = await getConversation({
    payload: {
      uuid: conversation_uuid,
      user_uuid: routeUserUuid.value || userUuid.value,
      is_summary_show_tables: isSummaryShowTables.value,
    },
    query: {
      apiKeyUuid: apiKeyUuid.value,
    },
  })
  const preparedMessages = data.messages.map((message) => {
    let textTechDetails = ''
    let textSummary = ''
    if (message.answer) {
      const text = message.answer.replace(/<div class="tech-details-start"\/>/g, '')
      const textParts = text.split('<div class="tech-details-end"/>')
      if (textParts.length > 1) {
        textTechDetails = textParts[0]
        textSummary = textParts[1]
      } else {
        textSummary = textParts[0]
      }
    }
    const feedback = message.feedback || { liked: null, comments: [] }
    return {
      ...message,
      feedback,
      type: message.role,
      prompt: message.prompt,
      textTechDetails,
      textSummary,
      isLoading: false,
      key: uuid.v4(),
    }
  })
  messages.value = [...messages.value, ...preparedMessages]
}

watch(() => props.conversation, (val1, val2) => {
  if (val1 === val2) return
  handleGetConversation({ conversation_uuid: props.conversation?.uuid })
})

async function refreshIframe() {
  await handleGetAiAnalystItem()
  await handleGetConversation({ conversation_uuid: conversationUuid.value })
}

async function schemaChangedFinish() {
  console.log('schemaChangedFinish');
  try {
    await refreshIframe()
    await prepareAnswerForPrompt()
  } catch (error) {
    console.log(error)
  }
}

function schemaChangedError() {
  console.log('schemaChangedError');
  isLoading.value = false
}

const {
  initMessageListeners,
  destroyMessageListeners,
  sendMessageToParent,
} = useIframeMessaging({
  isDisabled,
  refreshIframe,
  schemaChangedFinish,
  schemaChangedError,
})

messages.value.push(generateInitialMessage())

const initMessage = () => reactive({
  uuid: messageUuid.value,
  type: null,
  title: '',
  text: '',
  textTechDetails: '',
  textSummary: '',
  isTextTechDetails: true,
  prompt: '',
  data: null,
  charts: null,
  isLoading: false,
  feedback: { liked: null, comments: [] },
  error: null,
  key: uuid.v4(),
})

let currentMessage = initMessage()

const getTaskId = (text) => {
  const regex = /"task_id":\s*"(.+?)"/
  const match = text.match(regex)
  if (match) {
    taskId.value = match[1]
    console.log('taskId', taskId.value)
    return match[1]
  }
  return match ? match[1] : null
}

const handleCancelTask = async () => {
  if (taskId.value) {
    try {
      await cancelTask({
        query: { task_id: taskId.value, apiKeyUuid: apiKeyUuid.value },
        payload: {
          uuid: routeConversationUuid.value || conversationUuid.value,
          user_uuid: routeUserUuid.value || userUuid.value,
          descriptor_base_path: routeDescriptorBasePath.value,
        },
      })
    } catch (e) {
      console.error('Error while canceling task')
    } finally {
      taskId.value = null
      currentMessage.isLoading = false
      // currentMessage.error = 'Task was canceled'
    }
  }
}

const handleEventSourceWithBody = async (messageKey, endpoint) => {
  taskId.value = null
  currentMessage.uuid = messageUuid.value
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conversation: {
        uuid: routeConversationUuid.value || conversationUuid.value,
        user_uuid: routeUserUuid.value || userUuid.value,
        descriptor_base_path: routeDescriptorBasePath.value,
      },
      message: {
        uuid: messageUuid.value,
        user_uuid: routeUserUuid.value || userUuid.value,
        conversation_uuid: routeConversationUuid.value || conversationUuid.value,
        prompt: String(prompt.value),
        use_cache: getUseCache(),
      }
    }),
  })

  // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let { value, done } = await reader.read();
    if (done) break;

    let techDetailsStart = false;
    let techDetailsEnd = false;

    if (taskId.value === null) {
      getTaskId(value)
    }

    if (value?.includes('<div class="tech-details-start"/>')) {
      techDetailsStart = true
      currentMessage.isTextTechDetails = true
    }

    if (value?.includes('<div class="tech-details-end"/>')) {
      techDetailsEnd = true
      currentMessage.isTextTechDetails = false
    }

    if (techDetailsStart && techDetailsEnd) {
      let splitted1 = value.split('<div class="tech-details-start"/>')
      let [techDetails, textSummary] = splitted1[1].split('<div class="tech-details-end"/>')
      currentMessage.textTechDetails += `${techDetails}`
      currentMessage.textSummary += `${textSummary}`
    } else if (techDetailsStart) {
      let splitted1 = value.split('<div class="tech-details-start"/>')
      let techDetails = splitted1[1]
      currentMessage.textTechDetails += `${techDetails}`
    } else if (techDetailsEnd) {
      let [techDetails, textSummary] = value.split('<div class="tech-details-end"/>')
      currentMessage.textTechDetails += `${techDetails}`
      currentMessage.textSummary += `${textSummary}`
    } else {
      if (value && currentMessage.isTextTechDetails && value !== '<div class="tech-details-start"/>') {
        currentMessage.textTechDetails += `${value}`
      }
      if (value && !currentMessage.isTextTechDetails && value !== '<div class="tech-details-end"/>') {
        currentMessage.textSummary += `${value}`
      }
    }

    scrollToBottom('messages')
    console.log('Received', value);
  }
  sendMessageToParent(eventTypes.AI_ANALYST_STOPPED_IFRAME)
}

const handleGetStreamAnswer = async () => {
  await handleEventSourceWithBody('text', `${API_BASE_URL}/project/exploration/conversation/stream?apiKeyUuid=${apiKeyUuid.value}`)
}

const handleCreateConversation = async () => {
  const { data } = await createConversation({
    payload: {
      conversation: {
        uuid: routeConversationUuid.value || conversationUuid.value,
        user_uuid: routeUserUuid.value || userUuid.value,
        descriptor_base_path: routeDescriptorBasePath.value,
      },
      message: {
        uuid: messageUuid.value,
        user_uuid: routeUserUuid.value || userUuid.value,
        conversation_uuid: routeConversationUuid.value || conversationUuid.value,
        prompt: String(prompt.value),
      },
    },
    query: {
      apiKeyUuid: apiKeyUuid.value,
    },
  })
  return data.charts
}

const handleStreamAnswer = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    getConversations()
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    currentMessage.charts = await getMessageCharts(messageUuid.value)
    currentMessage.tables = await getMessageTables(messageUuid.value)
    scrollToBottom('messages')
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}


const onSetMessage = (message) => {
  prompt.value = message
}

function checkCache() {
  let isSomeDatasetCacheExpired = false
  isSomeDatasetCacheExpired = descriptorData.value.datasets.some(dataset => {
    let isCacheTimedOut = false;
    const cacheTimeout = dataset.data_provider.dataset.cache_timeout; // Timeout in seconds
    console.log('cacheTimeout :>> ', cacheTimeout);
    if (cacheTimeout === -1) {
      return isCacheTimedOut
    }
    // get utc time
    const currentTime = new Date().getTime();
    // const currentTime = Date.now();
    console.log('currentTime :>> ', currentTime);
    console.log('new Date(cacheCreatedAt).toUTCString() :>> ', new Date(currentTime).toUTCString());
    const cacheCreatedAt = dataset.data_provider.dataset.cache_created_at; // Timestamp in milliseconds
    console.log('new Date(cacheCreatedAt).toUTCString() :>> ', new Date(cacheCreatedAt).toUTCString());
    const cacheExpirationTime = cacheCreatedAt + (cacheTimeout * 1000); // Timeout converted to milliseconds
    console.log('cacheExpirationTime :>> ', cacheExpirationTime);
    console.log('diff :>> ', currentTime - cacheExpirationTime);
    isCacheTimedOut = currentTime > cacheExpirationTime;
    console.log('isCacheTimedOut :>> ', isCacheTimedOut);
    return isCacheTimedOut
  })
  return isSomeDatasetCacheExpired
}

async function handleResetCache() {
  console.log('handleResetCache');
  console.log('descriptorData.value :>> ', descriptorData.value);
  const { data } = await resetCacheProject({
    payload: {
      ...descriptorData.value,
    },
    query: {
      descriptor_base_path: routeDescriptorBasePath.value,
      apiKeyUuid: apiKeyUuid.value,
    },
  })
  console.log('data :>> ', data);
  descriptorData.value = data
}

function isSomeDatasetSchemeChanged() {
  let isSomeSchemeChanged = false
  isSomeSchemeChanged = descriptorData.value.datasets.some(dataset => {
    return dataset?.data_provider.dataset?.is_schema_changed
  })
  return isSomeSchemeChanged
}

async function prepareAnswerForPrompt() {
  if (!conversationUuid.value && !routeConversationUuid.value) {
    conversationUuid.value = uuid.v4()
  }
  messageUuid.value = uuid.v4()
  messages.value.push({
    type: 'user',
    prompt: prompt.value,
    feedback: { liked: null, comments: [] },
  })
  currentMessage = initMessage()
  messages.value.push(currentMessage)

  try {
    await handleStreamAnswer()
  } finally {
    prompt.value = null
    isLoading.value = false
    currentMessage.isLoading = false
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
  }
}


function checkIsAllDatasetsAutoDescribe() {
  const datasetsChangedSchema = descriptorData.value.datasets
    .filter(dataset => dataset?.data_provider.dataset?.is_schema_changed)

  const isSomeManualDescribe = datasetsChangedSchema
    .some(someDataset => someDataset.description.auto_describe === false)
  const isAllAutoDescribe = !isSomeManualDescribe
  return isAllAutoDescribe
}

async function preparationBeforeAnswer() {
  let isSomeSchemeChanged = false
  let isAllDatasetsAutoDescribe = null

  const isSomeDatasetCacheExpired = checkCache()
  if (isSomeDatasetCacheExpired) {
    await handleResetCache()
    isSomeSchemeChanged = isSomeDatasetSchemeChanged()
    console.log('isSomeSchemeChanged :>> ', isSomeSchemeChanged);
    if (isSomeSchemeChanged) {
      isAllDatasetsAutoDescribe = checkIsAllDatasetsAutoDescribe()
    }
  }
  return {
    isSomeSchemeChanged,
    isAllDatasetsAutoDescribe,
  }
}

const onClickSend = async () => {
  if (!prompt.value || isDisabled.value || isLoading.value) return
  sendMessageToParent(eventTypes.AI_ANALYST_STARTED_IFRAME)
  isLoading.value = true
  try {
    const { isSomeSchemeChanged, isAllDatasetsAutoDescribe } = await preparationBeforeAnswer()
    if (isSomeSchemeChanged) {
      sendMessageToParent(eventTypes.AI_ANALYST_SCHEMA_CHANGED)
      return
    }
    await prepareAnswerForPrompt()
  } catch (error) {
    console.log(error)
    isLoading.value = false
  }
}

const onSelectFeedback = (message = {}) => {
  const messageToUpdate = messages.value.find((m) => m.uuid === message.uuid)
  if (messageToUpdate) {
    messageToUpdate.feedback = messageToUpdate.feedback || { liked: null, comments: [] }
    messageToUpdate.feedback.liked = message.feedback.liked
    messageToUpdate.feedback.comments = message.feedback.comments
    messageToUpdate.key = uuid.v4()
  }
}

onMounted(async () => {
  try {
    userUuid.value = routeUserUuid.value || localStorage.getItem('userUuid')
    if (!userUuid.value) {
      store.dispatch('user/createUserUuid')
      userUuid.value = localStorage.getItem('userUuid')
    }
    const data = await getConversations()
    const conversation = data?.[0]
    conversationUuid.value = route?.query?.conversationUuid || conversation?.uuid
    await handleGetConversation({ conversation_uuid: conversationUuid.value })
  } catch (error) {
    console.error(error)
  }
  scrollDetect('messages')
  initMessageListeners()
  handleGetAiAnalystItem()
})

onBeforeUnmount(() => {
  destroyMessageListeners()
})
</script>

<style lang="scss">
@import "o-body.scss";
</style>
