import { useRoute } from "vue-router"
import { useStore } from "vuex"

export const useConversation = () => {
  const store = useStore()
  const route = useRoute()

  const getConversations = async () => {
    const descriptorBasePath = route?.query?.descriptorBasePath
    const apiKeyUuid = route?.query?.apiKeyUuid
    const userUuid = route?.query?.userUuid
    return await store.dispatch('conversations/getConversations', {
      descriptorBasePath,
      apiKeyUuid,
      userUuid,
    })
  }

  const archiveConversation = async (payload) => {
    const apiKeyUuid = route?.query?.apiKeyUuid
    return await store.dispatch('conversations/archiveConversation', {
      apiKeyUuid,
      ...payload,
    })
  }

  return {
    getConversations,
    archiveConversation,
  }
}